import {keyframes, SvgIcon} from "@mui/material";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";
import React from "react";
import "./animated-food-radar-icon.css"

const iconSpin = keyframes`
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
`

const appear = keyframes`
  0% {

    scale: -1;
    opacity: 0;
    visibility: hidden;
    transform-origin: center;
  }
  100% {
    transform-origin: center center;
    visibility: visible;
    opacity: 100%;
  }
`

export const AnimatedFoodRadarIcon = (props: SvgIconProps) => <SvgIcon
    viewBox={"0 0 24 24"}
    sx={{
        animationName: `${iconSpin}`,
        animationDuration: "0.8s",
        animationTimingFunction: "linear",
        animationIterationCount: 2,
    }}
    {...props}
>
    <path
        id="radar-line"
        d="m 13.005714,4.1625888 c 1.030981,0.1306029 2.019161,0.4611802 2.911746,0.9621086 1.785163,1.0018451 3.187924,2.6850822 3.785894,4.8126506 1.103943,3.92781 -0.92316,7.987733 -4.593241,9.528223 l -0.546558,-1.944656 c 2.604194,-1.230523 4.018013,-4.183203 3.214406,-7.042422 C 17.330164,8.885226 16.276886,7.6231361 14.937238,6.8713186 L 13.959607,8.613314 c 0.892579,0.500928 1.593992,1.342507 1.892947,2.406328 0.503273,1.790618 -0.309596,3.629138 -1.84368,4.527741 l -0.579026,-2.060178 c 0.482914,-0.499293 0.697536,-1.21402 0.497315,-1.926417 -0.297629,-1.058972 -1.407585,-1.681877 -2.466552,-1.384249 -1.058967,0.297627 -1.6818782,1.407581 -1.384249,2.466548 0.200228,0.712398 0.75848,1.220292 1.42812,1.385234 l 2.240322,7.971184 0.962699,-0.270575 C 20.021601,20.235361 23.122321,14.710298 21.628753,9.396198 20.881967,6.7391495 19.127308,4.6354434 16.895084,3.3826967 15.778974,2.7563234 14.543467,2.3426913 13.254861,2.179 Z"/>
    <path
        id="radar-burger"
        style={{
            animationName: `${appear}`,
            animationDuration: "1s",
            animationTimingFunction: "linear",
            animationDelay: "0.7s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            visibility: "hidden",
        }}
        d="M 7.00095,2 C 4.148214,2 1.849892,3.360002 2.009765,5 h 9.98237 C 12.152008,3.360002 9.853686,2 7.00095,2 Z M 3.664054,5.75 C 3.109494,5.75 2.799037,5.935313 2.574215,6.070313 2.389361,6.185313 2.279778,6.25 2,6.25 v 1 C 2.55456,7.25 2.864676,7.064688 3.104486,6.929688 3.28934,6.814688 3.399038,6.75 3.673819,6.75 3.948601,6.75 4.063182,6.81969 4.248035,6.929688 4.472857,7.064688 4.782336,7.25 5.336896,7.25 5.891457,7.25 6.201913,7.064688 6.426735,6.929688 6.611588,6.814688 6.721172,6.75 7.00095,6.75 7.275732,6.75 7.390313,6.81969 7.575166,6.929688 7.799988,7.064688 8.110444,7.25 8.665004,7.25 9.219564,7.25 9.529044,7.064688 9.753865,6.929688 9.938719,6.814688 10.048304,6.75 10.328081,6.75 c 0.274782,0 0.390339,0.06969 0.575193,0.179688 0.224821,0.135 0.534414,0.315312 1.083978,0.320312 V 6.259766 c 0,0 -0.395221,-0.07945 -0.580075,-0.189453 C 11.182356,5.935313 10.872876,5.75 10.318316,5.75 9.763756,5.75 9.454276,5.935313 9.229454,6.070313 9.044601,6.185313 8.929044,6.25 8.654262,6.25 8.37948,6.25 8.2649,6.18031 8.080046,6.070313 7.855225,5.935313 7.545745,5.75 6.991185,5.75 6.436625,5.75 6.127145,5.935313 5.902324,6.070313 5.71747,6.185313 5.606909,6.25 5.327131,6.25 5.052348,6.25 4.937769,6.18031 4.752915,6.070313 4.528094,5.935313 4.218614,5.75 3.664054,5.75 Z M 2,8 v 1 c 0,0.55 0.449454,1 0.999018,1 h 7.994099 c 0.549564,0 0.999018,-0.45 0.999018,-1 V 8 Z"
    />
</SvgIcon>
